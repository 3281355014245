<template>
    <div class="margin-tb-25">
        <!-- 查询条件 -->
        <search-bar @doSearch="doSearch"></search-bar>
        <!-- 产品列表 -->
        <product-list ref="productList" v-if="isFinish"></product-list>
        <!-- 选中分类 -->
        <category-dialog v-if="showCategoryDialog" :show.sync="showCategoryDialog" @finish="isFinish=true"></category-dialog>
    </div>
</template>

<script>
import SearchBar from './components/search-bar'
import ProductList from './components/product-list'
import CategoryDialog from './components/category-dialog'

export default {
    components: {
        SearchBar,
        ProductList,
        CategoryDialog
    },
    data () {
        return {
            showCategoryDialog: false,
            isFinish: false
        }
    },
    created () {
        this.checkCategory()
        this.$store.commit('productCenter/PRODUCT_BREADCRUMBS_ADD_TYPE', {
            to: { path: '/product' },
            name: 'Find products',
            root: true
        })
    },
    methods: {
        doSearch (params) {
            this.$refs.productList && this.$refs.productList.doSearch(params)
        },
        checkCategory () {
            this.$api.checkMemberCategory({}).then(res => {
                if (res.code === 0) {
                    this.showCategoryDialog = !(res.data)
                    this.isFinish = (res.data)
                } else {
                    this.isFinish = true
                }
            }).catch(() => {
                this.isFinish = true
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.product-wrap{
    background: white;
}
</style>
