<template>
    <div class="main-content-wrap">
        <div class="product-list">
            <div class="opertion-wrap">
                <el-button-group>
                    <el-button :class="{'active': searchForm.specMode }"
                        @click="handleSelect(true)">Random
                    </el-button>
                    <el-button :class="{'active': !searchForm.specMode }"
                        @click="handleSelect(false)">Hot sale
                    </el-button>
                </el-button-group>
                <el-button @click="allImportShopify">Import all</el-button>
            </div>
            <div class="list-wrap" v-loading="loading">
                <el-row :gutter="0">
                    <el-col :span="4"
                        v-for="(product, index) in productList"
                        :key="index">
                        <div class="product-card" :class="{ 'loading': product.loading }"
                            @mouseover="handleProductOver(product, 'show')"
                            @mouseleave="handleProductLeave(product, 'show')"
                            @click="jumpDetail(product)">
                            <div style="padding: 16px;">
                                <!-- my product 标志 -->
                                <div class="store-image" v-if="product.pushStatus === 2"><i class="iconfont iconshop_no"></i></div>
                                <transition name="el-fade-in">
                                    <div v-if="product.importStatus !== 0 && !product.isRemove"
                                        class="status success">
                                        <i class="el-icon-check"></i>
                                    </div>
                                    <div v-if="product.importStatus > 0 && product.isRemove"
                                        class="status delete">
                                        <i class="el-icon-close"></i>
                                    </div>
                                </transition>
                                <div class="img-wrap">
                                    <rap-image class="img" :src="product.imageUrl"></rap-image>
                                </div>
                                <div class="supplier-name">{{ product.supplierName }}</div>
                                <div class="sku-name">{{ product.skuName }}</div>
                                <div class="star-wrap">
                                    <rap-rate v-model="product.star"
                                        disabled
                                        show-score>
                                    </rap-rate>
                                </div>
                                <div class="prize-wrap">{{ product.sellCurrency | filterCurrent }} <span style="margin-left: 2px;">{{ product.sellPriceString }}</span></div>

                                <transition name="fade-collapse">
                                    <div class="btn-groups"
                                        v-show="product.show"
                                        @mouseover="handleProductOver(product, 'isRemove')"
                                        @mouseleave="handleProductLeave(product, 'isRemove')">
                                        <el-button v-if="product.importStatus !== 0"
                                            type="primary"
                                            plain
                                            :loading="product.loading"
                                            @click.stop="removeShopify(product)">
                                            {{ product.pushStatus === 2 ? 'Remove from My Product' : 'Remove from import list' }}
                                        </el-button>
                                        <el-button v-else
                                            type="primary"
                                            :loading="product.loading"
                                            @click.stop="importShopify(product)">
                                            Add to import list
                                        </el-button>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <rap-pagination class="page-border" :total="total" :currentPage="pageNum" :pageSize="pageSize" @change="pageChange"></rap-pagination>
    </div>
</template>

<script>
export default {
    data () {
        return {
            productList: [],
            searchForm: {
                specMode: true
            },
            isAnimate: false,
            total: 0,
            pageNum: 1,
            pageSize: 24,
            loading: false
        }
    },
    created () {
        this.reqProductLists()
    },
    methods: {
        // 选择是否热销|特价
        handleSelect (type) {
            this.searchForm.specMode = type
            this.doSearch()
        },
        // 鼠标移入商品
        handleProductOver (product, key) {
            this.$set(product, key, true)
        },
        // 鼠标移出商品
        handleProductLeave (product, key) {
            this.$set(product, key, false)
        },
        doSearch (params) {
            Object.assign(this.searchForm, params)
            // 重置页码
            this.reqProductLists()
        },
        pageChange (data) {
            this.pageNum = data.pageNum
            this.pageSize = data.pageSize
            this.reqProductLists()
        },
        /**
         * 请求商品列表
         */
        reqProductLists () {
            const prices = this.searchForm.prices || []
            const params = {
                brandId: this.searchForm.brandId || '',
                catId: this.searchForm.catId || '',
                limit: this.pageSize,
                pageNo: this.pageNum,
                priceEnd: prices[1] || '',
                priceStart: prices[0] || '',
                productName: this.searchForm.productName,
                specMode: this.searchForm.specMode ? 1 : 0,
                supplierId: this.searchForm.supplierId
            }
            this.loading = true
            this.$api.searchProducts(params).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.total = res.data.totalRecords || 0
                    this.productList = ((res.data && res.data.rows) || [])
                }
            }).catch(() => {
                this.loading = false
            })
        },
        /**
         * 批量导入shopify列表
         */
        allImportShopify () {
            const unImports = this.productList.filter(p => p.importStatus === 0)
            this.loading = true
            this.$api.importShopifyList({
                spuId: unImports.map(p => p.skuId)
            }).then(res => {
                this.loading = false
                if (res.code === 0) {
                    this.reqProductLists()
                    this.$notify.success('Import Success')
                }
            }).catch(() => {
                this.loading = false
            })
        },
        importShopify (product) {
            this.$set(product, 'loading', true)
            this.$api.importShopifyList({
                spuId: [product.spuId]
            }).then(res => {
                this.$set(product, 'loading', false)
                this.$set(product, 'show', false)
                if (res.code === 0) {
                    this.$notify.success('Import Success')
                    this.$set(product, 'importStatus', 1)
                }
            }).catch(() => {
                this.$set(product, 'loading', false)
                this.$set(product, 'show', false)
            })
        },
        /**
         * 移出shopify列表
         */
        removeShopify (product) {
            const key = product.pushStatus === 2 ? 'removeMyProducts' : 'removeShopifyList'
            this.$api[key]({
                spuId: [product.spuId]
            }).then(res => {
                if (res.code === 0) {
                    this.$notify.success('Remove Success')
                    this.$set(product, 'importStatus', 0)
                    this.$set(product, 'pushStatus', 0)
                }
            })
        },
        jumpDetail (product) {
            this.$store.commit('productCenter/PRODUCT_BREADCRUMBS_ADD_TYPE', {
                to: { path: `/product/detail/${product.skuId}/${product.spuId}` },
                name: product.skuName
            })
            this.$router.push({
                path: `/product/detail/${product.skuId}/${product.spuId}`
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.product-list{
    background: white;
    margin-top: 10px;
    border-radius: 2px;
    // padding-bottom: 30px;
    .opertion-wrap{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px 24px;
        .el-button{
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #303133;
            width: 100px;
            font-size: 14px;
            &.active{
                color: #ff6c23;
                background: #fff2eb;
                border-color:#ff6c23;
                border-right-color: #ff6c23;
            }
            &.active+.el-button{
                border-left-color: transparent;
                background: transparent;
            }
        }
    }
    .list-wrap{
        border-left: 1px solid $borderColor;
        border-top: 1px solid $borderColor;
        .product-card{
            min-height: 330px;
            box-sizing: border-box;
            position: relative;
            transition: all .1s;
            background: white;
            z-index: 2;
            border: 1px solid transparent;
            border-right-color: $borderColor;
            border-bottom-color: $borderColor;
            &:hover, &.loading{
                border-color: $borderColor;
                box-shadow: 0 -1px 10px rgba($color: #000000, $alpha: .25);
                transition: all .3s;
                z-index: 5;
            }
            .store-image {
                position: absolute;
                left: -2px;
                top: -1px;
                color: #faad14;
                z-index: 4;
                /deep/ i {
                    font-size: 32px;
                }
            }
            .status{
                display: inline-block;
                position: absolute;
                right: -1px;
                top: -1px;
                width: 33px;
                height: 33px;
                color: white;
                font-weight: 500;
                &::before {
                    display: inline-block;
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                }
                &.success::before {
                    border-top: 33px solid #4DB519;
                    border-left: 33px solid transparent;
                }
                &.delete::before {
                    border-top: 33px solid #FF4D4F;
                    border-left: 33px solid transparent;
                }
                i{
                    display: inline-block;
                    position: absolute;
                    top: 3px;
                    right: 3px;
                    z-index: 2;
                }
            }
            .img-wrap{
                width: 185px;
                height: 180px;
                background: #efefef;
                .img{
                    display: inline-block;
                    width: 185px;
                    height: 180px;
                }
            }
            .supplier-name{
                margin-top: 8px;
                opacity: 0.7;
                font-size: 12px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
            }
            .sku-name{
                margin-top: 5px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #000000;
                line-height: 20px;
                height: 40px;
                @include ellipsis(2);
            }
            .star-wrap{
                margin-top: 3px;
                /deep/ .el-rate__item .el-rate__icon{
                    margin-right: 4px;
                    font-size: 12px;
                }
                /deep/ .el-rate__text{
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                }
            }
            .prize-wrap {
                margin-top: 10px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #ff4d4f;
                line-height: 20px;
            }
            .btn-groups {
                position: absolute;
                top: 310px;
                left: -1px;
                right: -1px;
                z-index: 9;
                margin-top: 10px;
                padding: 15px 16px;
                background: white;
                display: flex;
                overflow: hidden;
                flex-direction: column;
                border: 1px solid $borderColor;
                border-top-color: transparent;
                box-shadow: -5px 8px 10px -4px rgba($color: #000000, $alpha: .2), 5px 8px 10px -4px rgba($color: #000000, $alpha: .2);
                .el-button{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    .page-border{
        border: 1px solid $borderColor;
    }
}
.fade-collapse-enter-active {
    height: 36px;
    transition: height .3s;
}
.fade-collapse-leave-active{
    height: 36px;
    transition: height 0s
}
.fade-collapse-enter, .fade-collapse-leave-active {
  height: 0;
}
</style>
