<template>
    <el-dialog :visible.sync="dialog"
        class="category-dialog"
        width="500px"
        :close-on-click-modal="false"
        @close="doNotRemind">
        <div slot="title" class="title">Select the product category you want to sell</div>
        <div class="content-wrap">
            <div class="category-item"
                v-for="(category, index) in dataList"
                :key="index"
                @click="handleSelect(category)">
                <div class="image-card">
                    <transition name="el-fade-in">
                        <div class="select" v-if="category.checked"><i class="el-icon-success"></i></div>
                    </transition>
                    <el-image :src="category.icon" alt="加载" lazy></el-image>
                </div>
                <el-tooltip
                    effect="dark"
                    :content="category.nameEn"
                    placement="bottom-start">
                    <div class="name">{{ category.nameEn }}</div>
                </el-tooltip>
            </div>
        </div>
        <div slot="footer" class="footer-wrap">
            <el-button type="text" @click="doNotRemind">Not reminding</el-button>
            <el-button type="primary" :disabled="nextDsabled" @click="doNext">NEXT</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['show'],
    data () {
        return {
            dialog: this.show,
            dataList: []
        }
    },
    watch: {
        show (nval, oval) {
            if (nval !== oval) {
                this.dialog = nval
            }
        },
        dialog (nval, oval) {
            if (nval !== oval) {
                this.$emit('update:show', nval)
            }
        }
    },
    created () {
        this.reqCategoryLists()
    },
    computed: {
        nextDsabled () {
            return !this.dataList.find(p => p.checked)
        }
    },
    methods: {
        // 选择
        handleSelect (category) {
            this.$set(category, 'checked', !category.checked)
        },
        doNotRemind () {
            this.$emit('finish')
            this.$emit('update:show', false)
        },
        doNext () {
            const categoryIds = []
            this.dataList.map(p => {
                if (p.checked) {
                    categoryIds.push(p.catId)
                }
            })
            this.reqSaveCategory(categoryIds)
        },
        // 保存偏好分类
        reqSaveCategory (categoryId) {
            this.$api.addMemberCategory({
                categoryId
            }).then(res => {
                if (res.code === 0) {
                    this.doNotRemind()
                }
            })
        },
        // 请求一级分类
        reqCategoryLists () {
            this.$api.getCategoryLevelOne({

            }).then(res => {
                if (res.code === 0) {
                    this.dataList = (res.data || [])
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.category-dialog{
    .title{
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
    }
    /deep/ .el-dialog__header{
        border-bottom: 1px solid rgba(0,0,0,0.06);
        padding-bottom: 15px;
    }
    .content-wrap{
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .category-item{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 82px;
            margin: 0 5px 10px 5px;
            padding: 5px 0;
            cursor: pointer;
            transition: all .1s;
            &:active {
                opacity: 0.6;
                transition: all .3s;
            }
            .el-image{
                width: 62px;
                height: 62px;
            }
            .name{
                margin-top: 5px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                @include ellipsis();
            }
            .image-card {
                width: 62px;
                height: 62px;
                position: relative;
                .select {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 20px;
                    color: $mainPrimary;
                    z-index: 5;
                    border-radius: 20px;
                    background: white;
                }
            }
        }
    }
    .footer-wrap{
        display: flex;
        justify-content: flex-end;
        .el-button--text{
            color: #4D4D4D;
        }
    }
    /deep/ .el-dialog__footer{
        border-top: 1px solid rgba(0,0,0,0.06);
        padding-bottom: 10px;
    }
}
</style>
