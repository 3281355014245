<template>
    <div class="search-bar main-content-wrap">
        <product-search-bar :brandId="searchForm.brandId" @doSearch="doSearch"></product-search-bar>
        <!-- 品牌 -->
        <div class="recommend-brand">
            <div class="title">OUR TOP SUPPLIER</div>
            <div class="sub-title">Quality suppliers</div>
            <div class="brand-list">
                <div :class="['brand-btn', { 'select': btn.value == searchForm.brandId }]"
                    v-for="btn in hotBrands"
                    :key="btn.value"
                    @click="selectHotBrand(btn)">
                    {{ btn.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductSearchBar from '@/views/rap/components/search-bar/product-search-bar.vue'
export default {
    components: {
        ProductSearchBar
    },
    data () {
        return {
            searchForm: {
                brandId: '',
                supplierId: '',
                prices: [1, 500],
                catId: ''
            },
            brands: [],
            suppliers: [],
            hotBrands: []
        }
    },
    mounted () {
        this.reqAllBrands()
        this.reqAllSuppliers()
        this.reqHotBrands()
        this.$bus.$on('ProductCategory', (catId) => {
            this.searchForm.catId = catId
            this.doSearch()
        })
    },
    methods: {
        // 搜索
        doSearch (data) {
            if (data) {
                Object.assign(this.searchForm, data)
            }
            this.$emit('doSearch', this.searchForm)
        },
        // 选择热门品牌
        selectHotBrand (item) {
            let value = item.value || ''
            if (this.searchForm.brandId === item.value) {
                value = ''
            }
            this.$set(this.searchForm, 'brandId', value)
            this.doSearch()
        },
        // 请求所有品牌
        reqAllBrands () {
            this.$api.getAllBrands({

            }).then(res => {
                if (res.code === 0) {
                    this.brands = (res.data || []).map(p => {
                        return {
                            label: p.name,
                            value: p.brandId
                        }
                    })
                }
            })
        },
        // 请求热门品牌
        reqHotBrands () {
            this.$api.getHotBrands({
                limit: 20
            }).then(res => {
                if (res.code === 0) {
                    this.hotBrands = (res.data || []).map(p => {
                        return {
                            label: p.name,
                            value: p.brandId
                        }
                    })
                }
            })
        },
        // 请求品牌
        reqAllSuppliers () {
            this.$api.getAllSuppliers({

            }).then(res => {
                if (res.code === 0) {
                    this.suppliers = (res.data || []).map(p => {
                        return {
                            label: p.name,
                            value: p.supplierId
                        }
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.search-bar{
    background: white;
    border-radius: 2px;
    .search-conditions{
        padding: 16px 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid #dedede;
        .product-name{
            width: 464px;
            /deep/ .el-button{
                background: $mainPrimary;
                color: white;
                width: 42px;
                height: 36px;
                padding-left: 0;
                padding-right: 0;
            }
        }
        .category-list{
            display: flex;
            flex-direction: row;
        }
    }
    .recommend-brand{
        padding: 16px 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border: 1px solid #dedede;
        border-top-color: transparent;
        .title{
            font-size: 18px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
            color: #1a1a1a;
        }
        .sub-title{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            color: #1a1a1a;
            margin-top: 5px;
        }
        .brand-list{
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            .brand-btn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                height: 30px;
                min-width: 95px;
                background: #ffffff;
                border-radius: 2px;
                font-family: PingFangSC, PingFangSC-Regular;
                margin-right: 10px;
                color: #4d4d4d;
                border: 1px solid #dbdbdb;
                transition: all .1s;
                position: relative;
                cursor: pointer;
                &.select{
                    background: #fff2eb;
                    border: 1px solid rgba($color: #ff6c23, $alpha: 0.4);
                    color: #FF6C23;
                    transition: all .3s;
                }
                &:active{
                    border-color: #ff6c23;
                }
            }
        }
    }
}
</style>
